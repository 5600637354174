/* Shared Styles */  
body { 
  color:#ffffff;
  font-family: sans-serif;
  min-height: 100vh;  
  background-color: #010420;
  background-image: url(/public/pogibg.webp);
  background-size: 50%; 
  background-repeat: repeat-x;
  line-height: 1.6; 
}
.red {
  color: rgb(255, 143, 143);
}
a {
  color:#2a7ab4;
}
label { 
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 5px 0px;
}
.articles-list {
  margin:0;
  padding:0;
  list-style: none;
}
.articles-list li {
  margin:0;
  padding:0;
}
button, .logout-button, .back-button, .action-button, .option-button {
  background-color:#0080A0;
  padding: 10px;
  font-size: 16px;
  border: none; 
  cursor: pointer;
  color:#fff;
  margin: 5px 5px 0px 0;
  border-radius: 4px;   
  text-shadow: 1px 1px #015c73;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 128, 160, 0.2);
}
button:hover {
  background-color: #009abf;
  box-shadow: 0 6px 15px rgba(0, 128, 160, 0.3);
  transform: translateY(-3px);
}
button.active {
  color:#fff;
  background-color: #006884;
  box-shadow: 0 2px 5px rgba(0, 128, 160, 0.2);
  transform: translateY(0);
}
button svg {    
  float: left;
}
.messages-nav {
  text-align:center;
  margin-top:10px;
}
.messages-nav button {
  font-size:14px;
  padding:6px 0;
  min-width:23%;
  background-color:rgb(255 255 255 / 21%);
  color:#fff;
  float:none;
}
input, textarea, select {
  width: 100%;
  padding: 10px 1%;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.message {
  border: 0;
  background: rgb(28 30 47);
  color: #fff;
  width: 70%;
}
.footer-meniu {
  text-align: center;
}
/* App.css */
.welcome-container, .signin-container, .contact-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 15px 20px;
}

.button-container, .options-container {
  margin-top: 20px;
}
.delete-button {
  padding: 5px;
  float: right;
  font-size: 14px;
  background-color: rgb(204 204 204 / 18%); 
}
h1, .header h2 {
  font-size: 24px;     
  font-weight: 900;  
  color: #ffffff;
  margin-bottom: 10px;
  text-transform: none;
  letter-spacing: normal;
}
h1 {
  font-size: 34px;    
  margin: 20px 0 0 0;
}
h1, h2, h3 {
  text-align: center;
  font-weight: 900;
  font-family:   sans-serif;
} 
h2 {
  font-size: 18px;
}
.footer-buttoon {
  padding: 6px 3px;
  font-size: 14px;
  background-color: transparent;
  color: #c9c8c8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: underline;
}

/* Button styles */
.action-button {
  background-color: #39287b;
}

.back-button {
  background-color: #39287b;
  color: #fff;
  margin-bottom:5px;
}

.logout-button {
  color: #ffffff;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
}

.option-button {
  background-color: #6c757d;
  color: #fff;
}

.action-button:hover, .back-button:hover, .logout-button:hover, .option-button:hover, .action-button.active  {
  background-color: #009abf; 
  color:#fff;
}
.languages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.language-tag {
  display: flex;
  align-items: center;
  background-color: #34374e;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  float: left;
  margin: 0 5px 2px 0;
}
 
.remove-icon {
  margin-left: 5px;
  color: #ff0000;
  cursor: pointer;
}

.language-selection {
  display: flex;
  align-items: center;
  gap: 10px;
}

.language-selection select {
  width: 200px;
}

.users-list { 
  margin-top: 5px;
  border-radius: 5px; 
} 
.users-list h3 { 
  margin:0;
  float: left;
}
.users-list .flag {
  margin: 0 5px 0 5px;
}
.users-list .list {
  padding: 5px 5px;
  display: grid;
}
.users-list .list:nth-child(even) { 
}

.users-list .list:nth-child(odd) {
  background-color: rgb(255 255 255 / 6%); /* Default background for odd rows */
}
.online, .offline { 
  position: absolute;
  background: #4eff4e;
  width: 5px;
  height: 5px;
  float: left;
  border-radius: 5px;
}

.offline { 
  background: #b0b0b0;
}
.gender_0 {
  color:#c3c3c3;
}
.gender_1 {
  color:#ffbec9;
}
.gender_2 {
  color:#b8b8ff;
}
.gender-selection {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.gender-buttons {
  margin-bottom: 5px;
}

.gender-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px; /* Width of the gender box */
  height: 80px; /* Height of the gender box */
  border: 2px solid #ccc; /* Default border color for the gender box */
  border-radius: 10px; /* Rounded corners for the gender box */
  cursor: pointer;
  transition: all 0.3s ease;
}

.gender-option:hover {
  border-color: #26b9dd; /* Border color when hovered */
}

.gender-option input[type="radio"] {
  display: none; /* Hide the radio button */
}

.gender-option .gender-icon {
  font-size: 40px; /* Size of the gender icon */
  margin-bottom: 8px;
}

/* Style for checked male option */
#genderMale:checked + .gender-icon {
  color: #0056ff;  /* Icon color for male when selected */
}

#genderMale:checked ~ .gender-label {
  border-color: #1E90FF; /* Border color for male when selected */
}

/* Style for checked female option */
#genderFemale:checked + .gender-icon {
  color: #FF69B4; /* Icon color for female when selected */
}

#genderFemale:checked ~ .gender-label {
  border-color: #FF69B4; /* Border color for female when selected */
}

.gender-option label {
  font-weight: bold;
  color: #666; /* Label text color */
}
.description section {
    margin-top: 20px;
    padding: 0px 0;
    text-align: center;
    width: 50%;
    display: inline-table;
}
 
.alert-message {
  background-color: #ffdddd; /* Light red background */
  color: #d8000c; /* Dark red text */
  border: 1px solid #d8000c;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-size: 0.9rem;
  animation: fadeOut 5s ease-out forwards;
}
.blur-image-container {
  position: relative;
  display: inline-block; /* Adjust as needed */
}

.blur-image {
  filter: blur(8px); /* Apply blur effect */
}
.description { 
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #cbcbcb;
  line-height: 1.5;
}

.description h2 {
  font-weight: 900;
  font-size: 22px;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: 10px;
  text-transform: none;
  letter-spacing: normal;
}
.description-icon {
  font-size: 48px; 
  color: #30d1f8;
}
.users-list div {
  cursor:pointer;
}
.users-list .image {
    float: left;
    height: 35px;
    width: 35px;
    margin: 0 5px 0 0;
    border-radius: 40%;
}
/* Header component */
.logo, .logo_inside {
  width: auto;
  width: 120px;  
  height: 32px; 
}
.loading-container {
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container p {
  clear:both;
  display:block;
}
.header {
  display: block;
  width: 100%; 
} 
.reviewing-text { 
  color:red;
}
.uploading {    
    background-color: #565656;
    z-index: 99;
}
 
.error-message, .response-message {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #721c24; /* Darker text color for better contrast */
}

/* Adjust message styles on focus/hover */
.error-message:focus, .error-message:hover,
.response-message:focus, .response-message:hover {
  background-color: #f5c6cb;
}

.rooms {   
  display: inline-block;
  text-align: center;
  width: 100%;
}
/* SignIn.css */
.signin-container {
  min-width: 300px;
}
.signin-container label {
  text-align: center;
}

 .start-chatting {
  background-color: #0080A0;
  border: none;
  border-radius: 27px;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
  padding: 15px 25px; 
  text-shadow: 1px 1px #015c73;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 128, 160, 0.2);
 }
 .start-chatting:hover {
  background-color: #009abf;
  box-shadow: 0 6px 15px rgba(0, 128, 160, 0.3);
  transform: translateY(-3px);
}
.start-chatting:active {
  background-color: #006884;
  box-shadow: 0 2px 5px rgba(0, 128, 160, 0.2);
  transform: translateY(0);
}
 .sign-nav {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
 }
 .start-icon {
  font-size: 24px;
  margin-right: 8px;
 }
.submit-button { 
  margin: 0 10px;  
} 
.submit-button, button[type="submit"], .send-button {
  background-color: #0080A0;
  color: #fff;
  border: 0; 
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase; 
   transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 10px rgba(0, 128, 160, 0.2);

}
.submit-button.no {
  background-color: transparent;
  color: #fff;
}
/* Adjust button styles on hover */
.submit-button:hover, button[type="submit"]:hover, .send-button:hover {
  background-color: #009abf; /* Darker shade of the logo color for hover */
  box-shadow: 0 6px 15px rgba(0, 128, 160, 0.3);
  transform: translateY(-3px);
}

.input-error input {
  border: 1px solid #dc3545;
}
.message-count {
  border-radius: 10%;
  background: #ff0000; 
}
/* Footer.css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
    padding: 20px;
    color: #000;
    background: #fff;
    max-width: 600px;
    max-height: 80vh;
    min-width: 300px;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align:left;
}

/* ChatBox.css */
.chat-box, 
.suggestions-container {
  padding: 10px;
  background-color:rgb(255 255 255 / 6%);
  border-radius: 4px;
}
 
.chat-box {
  display: flex;
  flex-direction: column;
  height: 60vh; /* Adjust as needed */
  min-height: 450px;
}
 

.message-container { 
  flex: 1; /* This makes it take up all available space */
  overflow-y: auto; /* Enable vertical scrolling */ 
  background-color: rgb(127 127 127 / 22%);
}
.message-textarea .message {
  flex: 1; /* Input takes up remaining space */ 
  margin-top:10px;
}


.message-textarea {
  border:none;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide scrollbar */
  width: 100%; /* Ensure it fills the available space */
  padding: 10px;
  font-size: 16px;
  line-height: 1.4;
  background-color: rgb(127 127 127 / 22%);
  border-radius: 4px; 
  color:#fff;
}

.message-input-area {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.char-counter {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}

.send-button {
  width: 30%;
  display: inline-block;
  padding: 11px 0 !important;
  min-width: 29px;
  margin: 0 0 0 10px;
}
.messages-nav {
  display: flex;
  justify-content: space-around;
}

.suggestions-container li,
.suggestions-container ul {
  margin:0;
  padding:0;
  list-style:none;
} 
.user-name-with-icon {
  display: inline-flex;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.suggestions-container li {
  margin:0;
} 
.chat-box h3 { 
  text-align: left;
  margin: 5px 0;
}
.chat-box .image {
   margin:0 10px 10px 0;
  float:left;
  width: 80px;
  height: 80px;
  border-radius:50%;
}

.message-container {
  height: 300px;
  overflow-y: scroll;
  clear:both; 
  margin: 10px 0 0 0;
}
.user-message, .other-message {
  line-height:20px;
}
 
.user-message, .other-message {
  margin: 5px;
  padding: 10px 12px;
  display: table;
  clear: both; 
}

.user-message {   
  background-color: rgb(46 181 233 / 46%);
  margin-left: auto;
  text-align: right;
  border-radius: 20px 20px 0 20px;
  color:#fff;
}

.other-message { 
  background-color:rgb(255 255 255 / 89%);
  margin-right: auto;
  text-align: left;
  color: #000;
  border-radius: 0px 20px 20px 20px;
}

.new-message {
  color: red;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

.rooms .action-button {  
  background-color: transparent;
  font-size: 34px;
  color: #30d1f8;
  padding: 0 20px;
  margin: 0;
  display: inline-grid;
  height: 60px;
  box-shadow: none;
}
.rooms .active.action-button {  
  color: #ffffff !important;
}


.rooms .action-button strong{ 
  clear: both;
  font-size: 15px;
  color: #fff;
  display: block;
  margin-top: -10px;
  padding: 0 1px;
}
.nav-title {  
  clear: both;
  margin-top: 10px;
  display: block;
  font-size: 18px;
}
.user-info {  
  float:right;
}

.user-info .profile-icon {
  font-size: 31px;
  color: #eeeeee; 
  margin-right: 5px;
  float:left;
}
.user-info img {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-right: 5px;
  float:left;
}
 
.user-info .user-name {    
  font-size: 14px;
  line-height: 28px; 
  display: inline-block;
  color: #fff;
}
 
.welcome ul {
  list-style-type: none;
  padding: 0;
}

.welcome li {
  padding-left: 20px; /* Adjust as needed */ 
  position: relative;
}

.welcome li:before {
  content: "•";
  color: #3595c4;
  font-size: 32px;
  position: absolute;
  left: 0;
  top: 14px;
  line-height: 0;
}

.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 15px 10px 0;
  float: left;
}

.profile-picture-container {
  display: inline-block; 
  width: 100%;
}

.profile-picture-container svg {
  font-size: 80px;
  margin: 0 15px 10px 0;
  float: left;
}
 
.cookie-consent-box {
  align-items: center;
  background-color: #000000d6;
  bottom: 0;
  box-shadow: 0 -2px 5px #0000001a;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 5px;
  position: fixed;
  line-height: 18px;
  right: 0;
}

.cookie-consent-box p { 
  margin:0;
  padding: 0;
}

.cookie-consent-box button {
  margin-left: 10px;
}

.recaptcha-container {
  margin: 0 auto;
  text-align: center;
}
/* typing bubbles */

.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; 
}
.seen-message {
  font-size: 12px;
  color: #aaa; 
  text-align: right;
  margin-right: 5px;
}

.bubble {
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
  margin: 0 2px;
  /* Animation */
  animation: bubbleFade 1.4s infinite ease-in-out both;
}

.bubble:nth-child(1) {
  animation-delay: -0.32s;
}

.bubble:nth-child(2) {
  animation-delay: -0.16s;
}

/* Style for the social media icons container */
.social-media-icons {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  margin-top: 20px;
}

/* Style for each social media icon link */
.icon-link {
  margin: 0 10px; /* Adds space between the icons */
  color: #555; /* Default icon color */
  transition: color 0.3s ease; /* Smooth transition when hovered */
  text-decoration: none; /* Remove underline */
}

/* Hover effect for the icons */
.icon-link:hover {
  color: #1da1f2; /* Change to your preferred color on hover */
}

/* Individual styles for different platforms */
.icon-link .fa-instagram:hover {
  color: #E4405F; /* Instagram brand color */
}

.icon-link .fa-facebook:hover {
  color: #3b5998; /* Facebook brand color */
}

.icon-link .fa-reddit:hover {
  color: #FF5700; /* Reddit brand color */
}

/* Resize the icons */
.icon-link svg {
  width: 35px;
  height: 35px;
}


@keyframes bubbleFade {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
 
@media (max-width: 700px) {
  .description section {
    width: 100%; /* Full width on smaller screens */
  } 
  body {
    background-size: 100%;
  }
  h1 {
    font-size: 22px;
  }
}
 